import React, { useState, createContext } from 'react';

interface Company {
  ticker: string;
  name: string;
  domain: string;
  stockPrice: number;
  marketCap: number;
}

type CompanyContextType = {
  selectedCompany: Company
  selectCompany: (company: Company) => void;
};

export const CompanyContext = createContext<CompanyContextType>({} as CompanyContextType);

type CompanyProviderProps = {
  children?: React.ReactNode;
}

export const CompanyProvider = (props: CompanyProviderProps) => {

  const [selectedCompany, setCompany] = useState(getInitialState);

  const selectCompany = (company: Company) => {
    setCompany(company);
    localStorage.setItem('selectedCompany', JSON.stringify(company));
  };

  function getInitialState() {
    const company = JSON.parse(localStorage.getItem('selectedCompany')) as Company;
    return company ? company : {
      ticker: 'TSLA',
      name: 'Tesla, Inc.',
      domain: 'tesla.com',
      stockPrice: null,
      marketCap: null
    };
  }

  return (
    <CompanyContext.Provider value={{ selectedCompany, selectCompany }}>
      {props.children}
    </CompanyContext.Provider>
  );
};
