import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { SidebarProvider } from 'src/contexts/SidebarContextType';
import { CompanyProvider } from './contexts/CompanyContext';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { Auth0Provider } from '@auth0/auth0-react';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/callback`,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "profile email"
    }}
    useRefreshTokens={true}
  >
    <HelmetProvider>
      <SidebarProvider>
        <CompanyProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CompanyProvider>
      </SidebarProvider>
    </HelmetProvider>
  </Auth0Provider>
);