import React, { FC, useContext } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Theme, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PageTitleWrapper from '../PageTitleWrapper';
import { CompanyContext } from '../../contexts/CompanyContext';

const CompanyLogoPageTitle: FC = () => {
  const { selectedCompany } = useContext(CompanyContext);
  return (
    <PageTitleWrapper>
      <Grid container ml={0} alignItems="center">
        <Grid ml={0}>
          {/* Clearbit Logo API: https://dashboard.clearbit.com/docs?ref=clearbit-blog.ghost.io#logo-api */}
          <Avatar
            variant={'rounded'} alt={selectedCompany.ticker}
            src={'https://logo.clearbit.com/' + selectedCompany.domain + '?size=160'}
            sx={{
              background: (theme: Theme) =>
                theme.palette.secondary.main
            }} />
        </Grid>
        <Grid ml={1} mt={0.5}>
          <Typography variant="h3" component="h3" gutterBottom>{selectedCompany.name}</Typography>
        </Grid>
        <Grid ml={2} />

        <Grid ml={0}>
          <Grid size={{ xs: 2.7, sm: 1.5, lg: 1.1 }}
                justifyContent="space-between"
                display={'flex'}>
            <Box textAlign={'left'} fontWeight={'bold'} minWidth={55}>Price:</Box>
            <Box textAlign={'right'} fontWeight={'bold'}>${selectedCompany.stockPrice}</Box>
          </Grid>
          <Grid size={{ xs: 2.7, sm: 1.5, lg: 1.1 }}
                justifyContent="space-between"
                display={'flex'}>
            <Box textAlign={'left'} fontWeight={'bold'} minWidth={55}>MCap:</Box>
            <Box textAlign={'right'} fontWeight={'bold'}>
              ${selectedCompany.marketCap !== undefined && selectedCompany.marketCap !== null ? (
              selectedCompany.marketCap > 1000000 ? (selectedCompany.marketCap / 1000000).toFixed(2) + 'T' :
                selectedCompany.marketCap > 1000
                  ? (selectedCompany.marketCap / 1000).toFixed(0) + 'B'
                  : selectedCompany.marketCap.toFixed(0) + 'M') : ('-')}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </PageTitleWrapper>
  );
};

CompanyLogoPageTitle.propTypes = {
  children: PropTypes.node.isRequired
};

export default CompanyLogoPageTitle;
