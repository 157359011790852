import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Card,
  Typography,
  useMediaQuery, useTheme
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Footer from 'src/components/Footer';
import SuspenseLoader from '../../../components/SuspenseLoader';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { useFetchWithAuth } from '../../../auth0/useFetchWithAuth';
import CompareMetricValue from '../CompareMetricValue';
import { Assessment } from '../Assessment';
import { MetricType } from '../MetricType';
import { Metrics } from '../metrics';
import CompanyLogoPageTitle from '../../../components/CompanyLogoPageTitle';

function MetricsSearch() {

  const { selectedCompany } = useContext(CompanyContext);
  const [metrics, setMetrics] = useState<Metrics | undefined>(undefined);
  const fetchWithAuth = useFetchWithAuth();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchWithAuth<Metrics>('/api/metrics/search?ticker=' + selectedCompany.ticker); // Replace with your API endpoint
      setMetrics(response);
    };
    fetchData().then();
  }, [selectedCompany.ticker]);

  if (!metrics) {
    return (<SuspenseLoader />);
  }

  return (
    <>
      <Helmet>
        <title>Metrics Search</title>
      </Helmet>

      <CompanyLogoPageTitle />

      <Container maxWidth="xl" sx={{ pl: isXs ? 0.2 : 2, pr: isXs ? 0.2 : 2 }}>
        <Card>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <Typography align={'center'} variant={'h5'} mb={1} maxWidth={isDownLg ? 180 : 310}>MANDATORY
              METRICS</Typography>
            <CompareMetricValue label={'TTM PE'} values={[metrics?.ttmPe]} />
            <CompareMetricValue label={'FORWARD PE'}
                                type={MetricType.FORWARD_PE}
                                values={[metrics?.forwardPe]}
                                assessments={[
                                  metrics?.forwardPe < metrics?.ttmPe ? Assessment.GOOD : Assessment.BAD
                                ]} />
            <CompareMetricValue label={'2 YEAR FORWARD PE'} values={[-1]} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <CompareMetricValue label={'TTM EPS GROWTH'}
                                values={[metrics?.ttmEpsGrowth]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'CRT YEAR EPS GROWTH' : 'CURRENT YR EXPECTED EPS GROWTH'}
                                values={[metrics?.currentYearEpsGrowth]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'NXT YEAR EPS GROWTH' : 'NEXT YR EXPECTED EPS GROWTH'}
                                type={MetricType.NEXT_YEAR_EXPECTED_EPS_GROWTH}
                                values={[metrics?.nextYearEpsGrowth]}
                                assessments={[
                                  metrics?.nextYearEpsGrowth > metrics?.ttmEpsGrowth ? Assessment.GOOD : Assessment.BAD
                                ]}
                                unit={'%'} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <CompareMetricValue label={'TTM REV GROWTH'}
                                values={[metrics?.ttmRevenueGrowth]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'CRT YEAR REV GROWTH' : 'CURRENT YR EXPECTED REV GROWTH'}
                                values={[metrics?.currentYearExpRevenueGrowth]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'NXT YEAR REV GROWTH' : 'NEXT YR EXPECTED REV GROWTH'}
                                type={MetricType.NEXT_YEAR_EXP_REVENUE_GROWTH}
                                values={[metrics?.nextYearExpRevenueGrowth]}
                                assessments={[
                                  metrics?.nextYearExpRevenueGrowth > metrics?.ttmRevenueGrowth ? Assessment.GOOD : Assessment.BAD
                                ]}
                                unit={'%'} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <CompareMetricValue label={'GROSS MARGIN TTM'}
                                values={[metrics?.grossMarginTtm]}
                                unit={'%'} />
            <CompareMetricValue label={'NET MARGIN TTM'}
                                values={[metrics?.netMarginTtm]}
                                unit={'%'} />
            <CompareMetricValue label={'TTM P/S RATIO'}
                                values={[metrics?.ttmPs]} />
            <CompareMetricValue label={'FORWARD P/S RATIO'}
                                values={[metrics?.forwardPs]} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <Typography align={'center'} variant={'h5'} marginBottom={1} maxWidth={isDownLg ? 180 : 310}>ADVANCED
              METRICS</Typography>
            <CompareMetricValue label={'LAST YEAR EPS GROWTH'}
                                values={[metrics?.lastYearEpsGrowth]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'LAST YEAR REV GROWTH' : 'LAST YEAR REVENUE GROWTH'}
                                values={[metrics?.lastYearRevenueGrowth]}
                                unit={'%'} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <CompareMetricValue label={'PEG RATIO'}
                                values={[metrics?.peg]} />
            <CompareMetricValue label={'RETURN ON EQUITY'}
                                values={[metrics?.roe]} unit={'%'} />
            <CompareMetricValue label={isXs ? 'RETURN ON INV CAP' : 'RETURN ON INVESTED CAPITAL'}
                                values={[metrics?.roic]} unit={'%'} />
            <CompareMetricValue label={'PRICE TO BOOK'}
                                values={[metrics?.priceToBook]} />
            <CompareMetricValue label={isXs ? 'P/FCF' : 'PRICE TO FREE CASH FLOW'}
                                values={[metrics?.priceToFcf]} />
            <CompareMetricValue label={isXs ? 'FCF YIELD' : 'FREE CASH FLOW YIELD'}
                                values={[metrics?.fcfYield]} unit={'%'} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <CompareMetricValue label={isXs ? 'DIV YIELD' : 'DIVIDEND YIELD'}
                                values={[metrics?.dividendYield]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'PYT RATIO' : 'PAYOUT RATIO'}
                                values={[metrics?.payoutRatio]}
                                unit={'%'} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <Typography pl={1} fontSize={11}>Numbers as
              of {new Date(metrics?.extractedAt).toLocaleString('de-CH', {
                dateStyle: 'medium',
                timeStyle: 'short'
              })}</Typography>
          </Container>

          <Grid container height={10} />

        </Card>
      </Container>

      <Footer />
    </>
  );
}

export default MetricsSearch;
