import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import ChartsRevenue from './content/charts/Revenue';
import MetricsSearch from './content/metrics/search';
import MetricsCompare from './content/metrics/compare';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const RuleNumberOne = Loader(lazy(() => import('src/content/dashboards/RuleNumberOne')));
const FinancialFortress = Loader(lazy(() => import('src/content/dashboards/FinancialFortress')));

// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="charts" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="rule1" replace />
      },
      {
        path: 'rule1',
        element: <RuleNumberOne />
      },
      {
        path: 'financial-fortress',
        element: <FinancialFortress />
      }
    ]
  },
  {
    path: 'metrics',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="search" replace />
      },
      {
        path: 'search',
        element: <MetricsSearch />
      },
      {
        path: 'compare',
        element: <MetricsCompare />
      }
    ]
  },
  {
    path: 'charts',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="revenue" replace />
      },
      {
        path: 'revenue',
        element: <ChartsRevenue />
      }
    ]
  }
];

export default routes;
